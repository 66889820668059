import dayjs from 'dayjs';

import { Order } from '@/api/types';
import { SessionEndReasonEnum } from '@/contexts/onSiteSession';
import { ItemCart, OrderChannel, Review } from '@/types';

import { getWebViewType } from './webview';

const formatPiSecret = (piSecret: string) => piSecret.substring(0, piSecret.indexOf('_secret_'));

const logEvent = (eventName: string, data: Record<string, string | number | undefined> = {}) => {
  window.dataLayer?.push({
    _clear: true,
    date: dayjs().format('DD/MM/YYYY'),
    event: eventName,
    time: dayjs().format('HH:mm:ss'),
    ...data,
  });
};

export const logLocationClick = () => {
  logEvent('request_location-click');
};

export const logDirectionsClick = (kitchenLabel: string) => {
  logEvent('directions-click', {
    kitchen_label: kitchenLabel,
  });
};

export const logOrderDelivery = ({
  conceptLabel,
  kitchenLabel,
  platformLabel,
}: {
  conceptLabel?: string;
  kitchenLabel: string;
  platformLabel?: string;
}) => {
  logEvent('order_delivery-click', {
    brand_label: conceptLabel,
    kitchen_label: kitchenLabel,
    platform_label: platformLabel,
  });
};

const logPaymentEvent = (
  eventName: string,
  kitchenLabel: string,
  conceptLabel: string,
  order: Order,
  piSecret: string,
  orderChannel?: OrderChannel
) => {
  logEvent(eventName, {
    payment_value: order.total_price,
    stripe_payment_intent_id: formatPiSecret(piSecret),
    order_type: order.pickup_time === null ? 'asap_order' : 'pre_order',
    order_number: order.number,
    brand_label: conceptLabel,
    kitchen_label: kitchenLabel,
    currency_code: order.currency,
    order_channel: orderChannel,
  });
};

const logCheckoutStarted = (kitchenLabel: string, conceptLabel: string, order: Order, piSecret: string) => {
  logPaymentEvent('stripe_payment_form_displayed', kitchenLabel, conceptLabel, order, piSecret);
};

const logPaymentSucceeded = (
  kitchenLabel: string,
  conceptLabel: string,
  order: Order,
  piSecret: string,
  orderChannel: OrderChannel
) => {
  logPaymentEvent('payment_success', kitchenLabel, conceptLabel, order, piSecret, orderChannel);
};

const logItemAdded = (kitchenLabel: string, conceptLabel: string, item: ItemCart, orderChannel: OrderChannel) => {
  logEvent('add_to_cart-click', {
    kitchen_label: kitchenLabel,
    brand_label: conceptLabel,
    item_label: item.label,
    item_value: item.sellingPrice,
    order_channel: orderChannel,
  });
};

const logItemRemoved = (kitchenLabel: string, conceptLabel: string, item: ItemCart, orderChannel: OrderChannel) => {
  logEvent('remove_from_cart-click', {
    kitchen_label: kitchenLabel,
    brand_label: conceptLabel,
    item_label: item.label,
    item_value: item.sellingPrice,
    order_channel: orderChannel,
  });
};

const logPageView = (pageName: string, kitchenLabel?: string, conceptLabel?: string, orderChannel?: OrderChannel) => {
  logEvent('custom_page_view', {
    page_name: pageName,
    webview_type: getWebViewType(),
    kitchen_label: kitchenLabel,
    ...(conceptLabel && { brand_label: conceptLabel }),
    order_channel: orderChannel,
  });
};

const logPongoClicked = (kitchenLabel: string, conceptLabel: string) => {
  logEvent('hey_pongo_button-click', {
    kitchen_label: kitchenLabel,
    brand_label: conceptLabel,
  });
};

const logOrderReviewed = (review: Review, kitchenLabel: string, conceptLabel: string) => {
  logEvent('order_feedback-click', {
    feedback_type: review === Review.GOOD ? 'positive' : 'negative',
    kitchen_label: kitchenLabel,
    brand_label: conceptLabel,
  });
};

const logGoogleReviewOpened = (kitchenLabel: string, conceptLabel: string) => {
  logEvent('action_after_order_feedback', {
    action_after_feedback: 'leave_review',
    kitchen_label: kitchenLabel,
    brand_label: conceptLabel,
  });
};

const logIntercomOpened = (kitchenLabel: string, conceptLabel: string) => {
  logEvent('action_after_order_feedback', {
    action_after_feedback: 'contact_support',
    kitchen_label: kitchenLabel,
    brand_label: conceptLabel,
  });
};

const logReviewExtraStepClosed = (kitchenLabel: string, conceptLabel: string) => {
  logEvent('action_after_order_feedback', {
    action_after_feedback: 'close_popup',
    kitchen_label: kitchenLabel,
    brand_label: conceptLabel,
  });
};

const logPaymentMethodSelectorOpened = (kitchenLabel: string, conceptLabel: string) => {
  logEvent('payment_tracker', {
    kitchen_label: kitchenLabel,
    concept_label: conceptLabel,
  });
};

const logPaymentMethodSelected = (
  kitchenLabel: string,
  conceptLabel: string,
  paymentMethod: string,
  other?: string
) => {
  logEvent('payment_method', {
    kitchen_label: kitchenLabel,
    concept_label: conceptLabel,
    payment_method: paymentMethod,
    ...(other && { other: other }),
  });
};

const logVideoItemEvent = (concept: string, item: string, kitchen: string) => {
  logEvent('see_item-click', {
    brand_label: concept,
    item_label: item,
    kitchen_label: kitchen,
  });
};

const logExploreMenuEvent = (concept: string, kitchen: string) => {
  logEvent('explore_menu-click', {
    brand_label: concept,
    kitchen_label: kitchen,
  });
};

const logLoginEvent = (accessType: 'PWA' | 'BROWSER') => {
  logEvent('login', {
    access_type: accessType,
  });
};

const logOrderingKioskCategoryClick = ({
  categoryName,
  kitchenLabel,
  brandLabel,
}: {
  categoryName: string;
  kitchenLabel: string;
  brandLabel?: string;
}) => {
  logEvent('ordering_kiosk_category-click', {
    category_name: categoryName,
    kitchen_label: kitchenLabel,
    brand_label: brandLabel,
  });
};

const logOnSiteSessionStart = ({ sessionId }: { sessionId: string }) => {
  logEvent('ordering_kiosk_session_start', {
    ordering_kiosk_session_id: sessionId,
  });
};

const logOnSiteSessionEnd = ({
  sessionEndReason,
  sessionId,
}: {
  sessionEndReason: SessionEndReasonEnum;
  sessionId: string;
}) => {
  logEvent('ordering_kiosk_session_end', {
    ordering_kiosk_session_id: sessionId,
    session_end_reason: sessionEndReason,
  });
};

const clickCart = ({
  brandLabel,
  kitchenLabel,
  orderChannel,
}: {
  brandLabel: string;
  kitchenLabel: string;
  orderChannel: 'onsite' | 'online';
}) => {
  logEvent('click_cart', {
    brand_label: brandLabel,
    kitchen_label: kitchenLabel,
    order_channel: orderChannel,
  });
};

const clickPay = ({
  brandLabel,
  currencyCode,
  kitchenLabel,
  orderChannel,
  orderNumber,
  orderType,
  paymentValue,
}: {
  brandLabel: string;
  currencyCode: string;
  kitchenLabel: string;
  orderChannel: 'onsite' | 'online';
  orderNumber?: string;
  orderType: 'preorder' | 'asap_order';
  paymentValue: number;
}) => {
  logEvent('click_pay', {
    brand_label: brandLabel,
    currency_code: currencyCode,
    kitchen_label: kitchenLabel,
    order_channel: orderChannel,
    order_number: orderNumber,
    order_type: orderType,
    payment_value: paymentValue,
  });
};

export default {
  clickCart,
  clickPay,
  logCheckoutStarted,
  logDirectionsClick,
  logEvent,
  logExploreMenuEvent,
  logGoogleReviewOpened,
  logIntercomOpened,
  logItemAdded,
  logItemRemoved,
  logLocationClick,
  logLoginEvent,
  logOnSiteSessionEnd,
  logOnSiteSessionStart,
  logOrderDelivery,
  logOrderingKioskCategoryClick,
  logOrderReviewed,
  logPageView,
  logPaymentMethodSelected,
  logPaymentMethodSelectorOpened,
  logPaymentSucceeded,
  logPongoClicked,
  logReviewExtraStepClosed,
  logVideoItemEvent,
};
